import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "contexts/AuthContext";
import Login from "pages/Login";
import { ProtectedRoute } from "components/ProtectedRoute";
import Dashboard from "pages/Dashboard";
import Autorizaciones from "pages/Autorizaciones";
import { esES as CoreEsES } from "@mui/material/locale";
import { esES as DataGridEsES } from "@mui/x-data-grid";
import { esES as PickersEsEs } from "@mui/x-date-pickers/locales";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#D0202D" },
    },
  },
  DataGridEsES,
  PickersEsEs,
  CoreEsES
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/autorizaciones"
              element={
                <ProtectedRoute>
                  <Autorizaciones />
                </ProtectedRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
