import React from "react";
import Private from "layouts/Private";
import Grid from "@mui/material/Grid";

const Dashboard = () => {
  return (
    <Private>
      <Grid item xs={8}>
        Dashboard
      </Grid>
    </Private>
  );
};

export default Dashboard;
