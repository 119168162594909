import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/UseAuth";

export const ProtectedRoute = ({ children }) => {
  const auth = useAuth();
  if (!auth.isAuthenticated) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};
