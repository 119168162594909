import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Navbar from "components/Navbar";

const Private = (props) => {
  const { children } = props;
  return (
    <Box>
      <Grid container>
        <Navbar />
        {children}
      </Grid>
    </Box>
  );
};

Private.prototypes = {
  children: PropTypes.node,
};

export default Private;
