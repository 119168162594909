import logo from "assets/mercycorps.png";

import {
  StyleSheet,
  Document,
  Page,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";

const Title = () => {
  const styles = StyleSheet.create({
    titleContainer: {
      flexDirection: "row",
      marginTop: 24,
      alignContent: "center",
      alignItems: "center",
    },
    logo: {
      width: "15%",
      height: "auto",
    },
    reportTitle: {
      width: "85%",
      fontSize: 9,
    },
  });

  return (
    <View style={styles.titleContainer}>
      <Image src={logo} style={styles.logo} />
      <Text style={styles.reportTitle}>
        Mercy Corps - Avanzando el Futuro Medellín - Colombia Teléfonos: Línea
        Amiga: +57 305 734 2879 E-mail: - - Web: www.mercycorps.org.co
      </Text>
    </View>
  );
};

const Participant = ({ autorization }) => {
  const styles = StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "row",
      marginTop: 24,
    },
    column: {
      width: "25%",
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.column}>
        <Text>Datos del Participante</Text>
        <Text>Identitificación:</Text>
        <Text>Nombre:</Text>
        <Text>Nombre Identitario:</Text>
        <Text>Teléfono:</Text>
        <Text>E-mail:</Text>
      </View>
      <View style={styles.column}>
        <Text>&nbsp;</Text>
        <Text>{autorization.numeroDocumento}</Text>
        <Text>{autorization.nombreCompleto}</Text>
        <Text>{autorization.nombreIdentitario}</Text>
        <Text>{autorization.numeroContacto}</Text>
        <Text>{autorization.email}</Text>
      </View>
      <View style={styles.column}>
        <Text>Autorización No.:</Text>
        <Text>Empresa:</Text>
        <Text>Autorizado por:</Text>
        <Text>Fecha de generación:</Text>
        <Text>Fecha de impresión:</Text>
        <Text>Vence:</Text>
      </View>
      <View style={styles.column}>
        <Text>{autorization.codigoAutorizacion}</Text>
        <Text>{autorization.empresa}</Text>
        <Text>{autorization.autorizadaPor}</Text>
        <Text>{autorization.fechaAutorizacion.toLocaleString()}</Text>
        <Text>{autorization.fechaImpresion.toLocaleString()}</Text>
        <Text>{autorization.fechaVencimiento.toLocaleString()}</Text>
      </View>
    </View>
  );
};

const ServiceTableHeader = () => {
  const borderColor = "#d3d3d3";
  const styles = StyleSheet.create({
    disclaimer: {
      alignItems: "center",
      height: 20,
      width: "100%",
      textAlign: "center",
    },
    container: {
      flexDirection: "row",
      borderBottomColor: "#d3d3d3",
      backgroundColor: "#d3d3d3",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 20,
      textAlign: "center",
      fontStyle: "bold",
      flexGrow: 1,
    },
    description: {
      width: "60%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    qty: {
      width: "10%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    rate: {
      width: "15%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    amount: {
      width: "15%",
    },
  });

  return (
    <>
      <View style={styles.disclaimer}>
        <Text>
          Estimado participante, recuerde que la prestación de estos servicios
          son gratuitos para usted, por tanto no debe realizar pago alguno
        </Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.description}>Descripción del Servicio</Text>
        <Text style={styles.qty}>Cantidad</Text>
        <Text style={styles.amount}>Valor Unitario</Text>
        <Text style={styles.amount}>Sub Total</Text>
      </View>
    </>
  );
};

const ServiceTableRow = ({ autorization }) => {
  const borderColor = "#d3d3d3";
  const styles = StyleSheet.create({
    row: {
      flexDirection: "row",
      borderBottomColor: "#d3d3d3",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 20,
      fontStyle: "bold",
    },
    description: {
      width: "60%",
      textAlign: "left",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingLeft: 8,
    },
    qty: {
      width: "10%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: "right",
      paddingRight: 8,
    },
    rate: {
      width: "15%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: "right",
      paddingRight: 8,
    },
    amount: {
      width: "15%",
      textAlign: "right",
      paddingRight: 8,
    },
  });

  function Row({ codigo, descripcion, cantidad, valorUnidad }) {
    if (codigo && codigo !== "---") {
      return (
        <View style={styles.row} key={codigo.toString()}>
          <Text style={styles.description}>{descripcion}</Text>
          <Text style={styles.qty}>{cantidad}</Text>
          <Text style={styles.rate}>{valorUnidad}</Text>
          <Text style={styles.amount}>{cantidad * valorUnidad}</Text>
        </View>
      );
    }
  }

  return (
    <>
      <Row
        codigo={autorization.cups1}
        descripcion={autorization.servicio1}
        cantidad={autorization.cantidad1}
        valorUnidad={autorization.valorUnitario1}
      />
      <Row
        codigo={autorization.cups2}
        descripcion={autorization.servicio2}
        cantidad={autorization.cantidad2}
        valorUnidad={autorization.valorUnitario2}
      />
      <Row
        codigo={autorization.cups3}
        descripcion={autorization.servicio3}
        cantidad={autorization.cantidad3}
        valorUnidad={autorization.valorUnitario3}
      />
      <Row
        codigo={autorization.cups4}
        descripcion={autorization.servicio4}
        cantidad={autorization.cantidad4}
        valorUnidad={autorization.valorUnitario4}
      />
      <Row
        codigo={autorization.cups5}
        descripcion={autorization.servicio5}
        cantidad={autorization.cantidad5}
        valorUnidad={autorization.valorUnitario5}
      />
      <Row
        codigo={autorization.cups6}
        descripcion={autorization.servicio6}
        cantidad={autorization.cantidad6}
        valorUnidad={autorization.valorUnitario6}
      />

      <Row
        codigo={autorization.cups7}
        descripcion={autorization.servicio7}
        cantidad={autorization.cantidad7}
        valorUnidad={autorization.valorUnitario7}
      />
      <Row
        codigo={autorization.cups8}
        descripcion={autorization.servicio8}
        cantidad={autorization.cantidad8}
        valorUnidad={autorization.valorUnitario8}
      />
      <Row
        codigo={autorization.cups9}
        descripcion={autorization.servicio9}
        cantidad={autorization.cantidad9}
        valorUnidad={autorization.valorUnitario9}
      />
      <Row
        codigo={autorization.cups10}
        descripcion={autorization.servicio10}
        cantidad={autorization.cantidad10}
        valorUnidad={autorization.valorUnitario10}
      />
    </>
  );
};

const ServiceTableFooter = ({ autorization }) => {
  const borderColor = "#d3d3d3";
  const styles = StyleSheet.create({
    row: {
      flexDirection: "row",
      borderBottomColor: "#d3d3d3",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 20,
      fontSize: 9,
      fontStyle: "bold",
    },
    description: {
      width: "85%",
      textAlign: "right",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingRight: 8,
    },
    total: {
      width: "15%",
      textAlign: "right",
      paddingRight: 8,
    },
  });

  const total =
    autorization.cantidad1 * autorization.valorUnitario1 +
    autorization.cantidad2 * autorization.valorUnitario2 +
    autorization.cantidad3 * autorization.valorUnitario3 +
    autorization.cantidad4 * autorization.valorUnitario4 +
    autorization.cantidad5 * autorization.valorUnitario5 +
    autorization.cantidad6 * autorization.valorUnitario6 +
    autorization.cantidad7 * autorization.valorUnitario7 +
    autorization.cantidad8 * autorization.valorUnitario8 +
    autorization.cantidad9 * autorization.valorUnitario9 +
    autorization.cantidad10 * autorization.valorUnitario10;

  return (
    <View style={styles.row}>
      <Text style={styles.description}>TOTAL</Text>
      <Text style={styles.total}>{total}</Text>
    </View>
  );
};

const ServiceTable = ({ autorization }) => {
  const styles = StyleSheet.create({
    tableContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 24,
    },
  });

  return (
    <View style={styles.tableContainer}>
      <ServiceTableHeader />
      <ServiceTableRow autorization={autorization} />
      <ServiceTableFooter autorization={autorization} />
    </View>
  );
};

const AutorizationsPdf = ({ autorizations }) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 8,
      padding: 20,
    },
  });

  return (
    <Document>
      {autorizations.map((autorization) => (
        <Page key={autorization.id} size="A4" style={styles.page}>
          <Title />
          <Participant autorization={autorization} />
          <ServiceTable autorization={autorization} />
        </Page>
      ))}
    </Document>
  );
};

export default AutorizationsPdf;
