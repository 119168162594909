async function http(path, args) {
  const headers = new Headers();

  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");
  headers.set("Access-Control-Allow-Origin", "http://localhost:3000");

  const value = window.localStorage.getItem("user");
  if (value) {
    const user = JSON.parse(value);
    if (user && user.access_token) {
      headers.set("Authorization", `Bearer ${user.access_token}`);
    }
    
  }

  args.headers = headers;

  return await fetch(path, args);
}

export async function get(path) {
  const response = await http(path, { method: "GET" });
  if (response.ok) {
    response.parsedBody = await response.json();
  }
  return response;
}

export async function post(path, args) {
  return await http(path, { method: "POST", body: JSON.stringify(args) });
}

export async function put(path, args) {
  return await http(path, { method: "PUT", body: JSON.stringify(args) });
}
