import React, { useState, useEffect } from "react";
import Private from "layouts/Private";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { get } from "services/ApiService";
import AutorizationsPdf from "components/AutorizationsPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";

const columns = [
  {
    field: "formid",
    headerName: "ID",
  },
  {
    field: "numeroDocumento",
    headerName: "Documento",
    sortable: false,
    flex: 1,
  },
  {
    field: "nombreCompleto",
    headerName: "Nombre",
    sortable: false,
    flex: 1,
  },
  {
    field: "nombreIdentitario",
    headerName: "Nombre Identitario",
    sortable: false,
    flex: 1,
  },
  {
    field: "empresa",
    headerName: "Empresa",
    sortable: false,
    flex: 1,
  },
  {
    field: "codigoAutorizacion",
    headerName: "Código Autorización",
    sortable: false,
    flex: 1,
  },
  {
    field: "autorizadaPor",
    headerName: "Autorizada Por",
    sortable: false,
    flex: 1,
  },
];

const Autorizaciones = () => {
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 100 });
  const [selectedRows, setSelectedRows] = useState([]);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    (async () => {
      var url = `http://190.8.178.41:5000/autorizations?page${pagination.page}&per_page=${pagination.pageSize}`;

      if (filter.items) {
        const empresa = filter.items.find((i) => i.field === "empresa");
        if (empresa && empresa.value) {
          url += `&empresa=${empresa.value}`;
        }
      }

      const response = await get(url);
      const data = await response.json();

      setRows(data.authorizations);
      setRowCount(data.pagination.total);
    })();
  }, [pagination, filter]);

  return (
    <Private>
      <Grid item xs={8}>
        <Typography variant="h4">Autorizaciones</Typography>
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            pagination
            paginationMode="server"
            onPaginationModelChange={setPagination}
            onRowSelectionModelChange={(ids) => {
              const newSelection = ids.map((id) =>
                rows.find((row) => row.formid === id)
              );
              setSelectedRows(newSelection);
            }}
            columns={columns}
            columnVisibilityModel={{ formid: false }}
            rows={rows}
            rowCount={rowCount}
            filterMode="server"
            onFilterModelChange={(filter) => {
              setFilter(filter);
            }}
            getRowId={(row) => row.formid}
          />
        </div>
        <div>
          {selectedRows.length > 0 ? (
            <PDFDownloadLink
              document={<AutorizationsPdf autorizations={selectedRows} />}
              fileName="autorizaciones.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? "Procesando.." : "Descargar!"
              }
            </PDFDownloadLink>
          ) : (
            <></>
          )}
        </div>
      </Grid>
    </Private>
  );
};

export default Autorizaciones;
